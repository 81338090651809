import React from "react"

import GoogleImage from "./GoogleImage"

import { storage } from "../settings"

const PDFDownloadBox = (props) => {
	return (
        <div style={{display:"flex", alignItems:"center", backgroundColor:"white", padding:"5px", border:"1px solid #ccc", borderRadius:"5px", margin:"15px 0px"}}>
            <a href={storage.url + props.pdfUrl} target="_blank">
                <GoogleImage required lazy src={props.imageUrl} style={{margin:"5px", maxHeight:"75px"}} alt="Solar Sign Lighting Instructions" />
            </a>
            <div style={{padding:"5px 10px"}}>
                A PDF version of this document is available for downloading and printing. <br />
                <a href={storage.url + props.pdfUrl} target="_blank">
                {props.title}</a> (PDF)							
            </div>
        </div>
	)
}

export default PDFDownloadBox