import React from "react"

import PageHeader from "../../components/layout/PageHeader"
import SupportPage from "../../components/layout/SupportPage"
import GoogleImage from "../../components/GoogleImage"
import PDFDownloadBox from "../../components/PDFDownloadBox"

const InstallationDmPage = () => {
	return (
		<SupportPage page="installation" title="EZLit Sign Installation Instructions">
			<PageHeader title="EZLit Sign Installation Instructions" />

            <PDFDownloadBox
				title="Download Installation Instructions"
                pdfUrl="documents/SignMonkey-Installation.pdf"
				imageUrl="SignTypes/Install-Guide-thumb.jpg"
            />

			<h3>Sign Parts <small>Names referred to in the Instructions</small></h3>

			<div>
				<GoogleImage src="Documents/installation-dm-01.jpg" required lazy alt="EZlit Channel Letters Installation Parts Included" className="full" />
			</div>

			<p>
				Tools Needed: Safety Glasses, Tape, Tape Measure, Level, Screws, Drill bits, Screw driver bit
			</p>

			<h3>Step 1</h3>

			<p>
				Unroll pattern and tape to wall where letters are to be installed.<br/>
				Check to make sure letters are level and centered in the area.
			</p>

			<p className="help-block">
				There must be access to the back of the wall to connect EZLit Power Link to the EZLit Power Strip. 
				Use a tape that will not damage the wall surface when it is removed.
				For brick you can use duct tape but remove as soon as done.
			</p>

			<br/>

			<p>
				Do not level with the bottoms of the letters or edge of paper, use provided level lines only.
			</p>

			<GoogleImage src="Documents/installation-dm-02.png" required lazy alt="Channel letter pattern level" className="full" />

			<h3>Step 2 <small>Drill Holes</small></h3>

			<div className="gridTextImage">
				<div>
					<p>Mounting Holes <GoogleImage src="Documents/mounting-holes.png" alt="Mouting Holes" />￼ are on the outside of Letters and Logos.</p>
					<p>Drill pilot holes through the center of the mark for appropriate fastener.</p>

					<p className="help-block">
						For wood surfaces we suggest galvanized wood screws. They can be painted to match wall. Concrete, Brick or cinderblock surfaces we suggest Tapcon® Screws.
						Your hardware store may suggest another type that will work for your application.
					</p>
				</div>
				<GoogleImage src="Documents/installation-dm-03.png" required lazy alt="Drill holes for mounting channel letters" />
			</div>
			<div className="gridImageText">
				<GoogleImage src="Documents/installation-dm-04.png" required lazy alt="Drill power hole for installing channel letters power" />
				<div>
					Power Holes <GoogleImage src="Documents/power-holes.png" required lazy alt="Power Holes" />￼ are in the middle of the Letters &amp; Logos.<br/>
					Drill a 3/4” hole completely through the wall to the inside of the building.
				</div>
			</div>


			<h3>Step 3 <small>Installing Letters</small></h3>

			<div className="gridTextImage">
				<div>
					Remove the pattern from the wall.
					<p className="help-block">
						It is best to remove tape as soon as possible.
					</p>
				</div>
				<GoogleImage src="Documents/installation-dm-05.png" required lazy alt="Channel letter installation sequence tag" />
			</div>

			<p>
				On the back of the Letters &amp; Logos there is a sticker, this sticker will tell you the sequence the Letters &amp; Logos are to be installed.
			</p>
			<p>
				Installation Letters &amp; Logos on the wall using screws appropriate for the wall.<br/>
				Screw is to be put through hole in the mounting tab and into pilot hole from pattern.
			</p>
			<p>
				While putting letters on the wall shove the EZLit Power Link through the wall so it can be reached from inside the building.
			</p>

			<GoogleImage src="Documents/installation-dm-06.png" required lazy alt="Install channel letter electrical wire. Mount channel letter to wall" className="full" />

			<h3>Step 4 <small>Install Power Strip</small></h3>

			<div className="gridTextImage">
				<div>
					<p>
						On the Back side of the wall, plug each letters in to the EZLit Power Strip and the EZLit Power Supply.
					</p>
					<p className="help-block">
						Each Power Link has a colored tag should match the color tag on the Power Strip.
					</p>
					<p>
						* Apply Dielectric Grease (included with sign) to metal parts of each Plug Connection
					</p>
					<p>
						Plug the EZLit Power Strip into a 110 volt outlet.
					</p>
					<p className="help-block">
						The Power Supply gets warm and should be in the open air.<br/>
						Do not put the power supply in a container or cover it with combustible materials.<br/>
						The sign will be on all the time. You can turn them on or off with the switch or purchase
						a lamp timer at the hardware store.
					</p>
					<p className="help-block">
						Power supply must be indoors, it is not weather proof.
					</p>
					<p>
						A loop of wire should hang down below the outlet.
					</p>
					<p className="help-block">
						This will prevent water running down cord and into the socket.
					</p>
				</div>
				<GoogleImage src="Documents/installation-dm-07.png" required lazy alt="Plug-n-play power installation for channel letters" className="full" />
			</div>

			<div className="warning glasses">
				<GoogleImage src="Documents/safteyglasses.png" required lazy alt="Safety Glasses" />
				Wear Safety Glasses when drilling holes
			</div>
		</SupportPage>
	)
}

export default InstallationDmPage